import React from 'react';
import {
  Link,
} from 'react-router-dom';

import dark from '../images/welcome/ryu_games_logo.png';

class DataUsagePage extends React.Component {
  render() {
    document.body.style = 'background: white;';

    const title = 'Privacy and Data Usage';
    const effectiveDate = 'Effective date: December 29, 2018';

    const introContent = `Ryu Games ("us", "we", or "our") operates
    the http://www.ryu.games website and the Ryu SDK suite (hereinafter referred to as the "Service").
    This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our
    Service and the choices you have associated with that data.
    We use your data to provide and improve the Service.
    By using the Service, you agree to the collection and use of information in accordance with this policy.
    Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings
    as in our Terms and Conditions.`;
    const definitionHeader = 'Definitions';

    const defs = [{
      word: 'Service',
      definition: `Service means the http://www.ryu.games website and Ryu
      SDK suite by Ryu Games`,
    },
    {
      word: 'Personal Data',
      definition: `Personal Data means data about a living individual who can be
      identified from those data (or from those and other information either in our
        possession or likely to come into our possession).`,
    }, {
      word: 'Usage Data',
      definition: `Usage Data is data collected automatically either generated by
      the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).`,
    }, {
      word: 'Cookies',
      definition: 'Cookies are small files stored on your device (computer or mobile device).',
    }, {
      word: 'Data Controller',
      definition: `Data Controller means the natural or legal person who
      (either alone or jointly or in common with other persons) determines the purposes
      for which and the manner in which any personal information are, or are to be, processed.
      For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.`,
    }, {
      word: 'Data Processors (or Service Providers)',
      definition: `Data Processor (or Service Provider) means any natural or legal person
      who processes the data on behalf of the Data Controller. We may use the services of
      various Service Providers in order to process your data more effectively.`,
    }, {
      word: 'Data Subject (or User)',
      definition: 'Data Subject is any living individual who is using our Service and is the subject of Personal Data.',
    }];

    const infoHeader = 'Information Collection and Use';
    const infoIntro = `We collect several different types of information for various
    purposes to provide and improve our Service to you.`;

    const infoHeader1 = 'Types of Data Collected';
    const infoSubHeader1 = 'Personal Data';
    const infosubpara1 = `While using our Service, we may ask you to provide us with certain personally
    identifiable information that can be used to contact or identify you ("Personal Data").
    Personally identifiable information may include, but is not limited to:`;

    const personaldata = ['Email address', 'First name and last name', 'Cookies and Usage Data'];
    const infosubpara2 = `We may use your Personal Data to contact you with newsletters,
    marketing or promotional materials and other information that may be of interest to you.
    You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or
    the instructions provided in any email we send.`;

    const infoSubHeader2 = 'Usage Data';
    const infosubpara3 = `We may also collect information that your browser sends whenever you visit our
    Service or when you access the Service by or through a mobile device ("Usage Data").`;
    const infosubpara4 = `This Usage Data may include information such as your computer's Internet Protocol address
    (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit,
    the time spent on those pages, unique device identifiers and other diagnostic data.`;
    const infosubpara5 = `When you access the Service with a mobile device, this Usage Data may include information such as
    the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device,
    your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and
    other diagnostic data.`;

    const infoSubHeader3 = 'Location Data';
    const infosubpara6 = `We may use and store information about your location if you give us permission
    to do so ("Location Data"). We use this data to provide features of our Service, to improve and customise our Service.`;
    const infosubpara7 = `We do not store unencrypted users' locations that can be attributed to the user.
    We store an aggregate of locations per game on our system. Users' locations are encrypted with industry
    standard 256-bit AES encryption and uses the single-use ECDH key agreement protocol.`;
    const infosubpara8 = `You can enable or disable location services when you use our Service at any time
    by way of your device settings.`;

    const infoSubHeader4 = 'Tracking Cookies Data';
    const infosubpara9 = `We use cookies and similar tracking technologies to track the activity on our Service
    and we hold certain information.`;
    const infosubpara10 = `Cookies are files with a small amount of data which may include an anonymous unique identifier.
    Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such
    as beacons, tags and scripts to collect and track information and to improve and analyse our Service.`;
    const infosubpara11 = `You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
    However, if you do not accept cookies, you may not be able to use some portions of our Service.`;
    const infosubpara12 = 'Examples of Cookies we may use:';

    const cookies = [{
      type: 'Session Cookies.',
      explaination: 'We use Session Cookies to operate our Service.',
    },
    {
      type: 'Preference Cookies.',
      explaination: 'We use Preference Cookies to remember your preferences and various settings.',
    },
    {
      type: 'Security Cookies.',
      explaination: 'We use Security Cookies for security purposes.',
    }];

    const useHeader = 'Use of Data';
    const usePara = 'Ryu Games uses the collected data for various purposes:';
    const uses = [
      'To provide and maintain our Service',
      'To notify you about changes to our Service',
      'To allow you to participate in interactive features of our Service when you choose to do so',
      'To provide customer support',
      'To gather analysis or valuable information so that we can improve our Service',
      'To monitor the usage of our Service',
      'To detect, prevent and address technical issues',
      `To provide you with news, special offers and general information about other goods,
      services and events which we offer that are similar to those that you have already
      purchased or enquired about unless you have opted not to receive such information`,
    ];

    const legalBasisHeader = 'Legal Basis for Processing Personal Data under the General Data Protection Regulation (GDPR)';
    const legalBasisPara1 = `If you are from the European Economic Area (EEA), Ryu Games
    legal basis for collecting and using the personal information described in this Privacy Policy depends on
    the Personal Data we collect and the specific context in which we collect it.`;
    const legalBasisPara2 = 'Ryu Games may process your Personal Data because:';
    const legalbasis = [
      'We need to perform a contract with you',
      'You have given us permission to do so',
      'The processing is in our legitimate interests and it is not overridden by your rights',
      'For payment processing purposes',
      'To comply with the law',
    ];

    const retentionHeader = 'Retention of Data';
    const retentionPara1 = `Ryu Games will retain your Personal Data only
    for as long as is necessary for the purposes set out in this Privacy Policy.
    We will retain and use your Personal Data to the extent necessary to comply with
    our legal obligations (for example, if we are required to retain your data to comply with applicable laws),
    resolve disputes and enforce our legal agreements and policies.`;
    const retentionPara2 = `Ryu Games will also retain Usage Data for internal analysis purposes.
    Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen
    the security or to improve the functionality of our Service, or we are legally obligated to retain this data
    for longer periods.`;

    const transferHeader = 'Transfer of Data';
    const transferPara1 = `Your information, including Personal Data, may be transferred to — and maintained on
    — computers located outside of your state, province, country or other governmental jurisdiction
    where the data protection laws may differ from those of your jurisdiction.`;
    const transferPara2 = `If you are located outside United States and choose to provide information to us,
    please note that we transfer the data, including Personal Data, to United States and process it there.`;
    const transferPara3 = `Your consent to this Privacy Policy followed by your submission of such information
    represents your agreement to that transfer.`;
    const transferPara4 = `Ryu Games will take all the steps reasonably necessary to ensure
    that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal
    Data will take place to an organisation or a country unless there are adequate controls in place including
    the security of your data and other personal information.`;

    const disclosureHeader = 'Disclosure of Data';
    const disclosureSub1 = 'Disclosure for Law Enforcement';
    const DisclosureSub1Para1 = `Under certain circumstances, Ryu Games
    may be required to disclose your Personal Data if required to do so by law or in response to
    valid requests by public authorities (e.g. a court or a government agency).`;
    const disclosureSub2 = 'Legal Requirements';
    const DisclosureSub2Para1 = `Ryu Games may disclose your Personal Data in the good
    faith belief that such action is necessary to:`;
    const legalRequirements = [
      'To comply with a legal obligation',
      'To protect and defend the rights or property of Ryu Games',
      'The processing is in our legitimate interests and it is not overridden by your rights',
      'To prevent or investigate possible wrongdoing in connection with the Service',
      'To protect the personal safety of users of the Service or the public',
      'To protect against legal liability',
    ];

    const securityHeader = 'Security of Data';
    const securityPara = `The security of your data is important to us but remember that no method of
    transmission over the Internet or method of electronic storage is 100% secure.
    While we strive to use commercially acceptable means to protect your Personal Data,
    we cannot guarantee its absolute security.`;

    const dntHeader = 'Our Policy on "Do Not Track" Signals under the California Online Protection Act (CalOPPA)';
    const dntPara1 = `We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in
    your web browser to inform websites that you do not want to be tracked.`;
    const dntPara2 = 'You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.';

    const yourRightsHeader = 'Your Data Protection Rights under the General Data Protection Regulation (GDPR)';
    const yourRightsHeaderPara1 = `If you are a resident of the European Economic Area (EEA),
    you have certain data protection rights. Ryu Games
    aims to take reasonable steps to allow you to correct, amend, delete or limit the use of your Personal Data.`;
    const yourRightsHeaderPara2 = `If you wish to be informed about what Personal Data we hold about you
    and if you want it to be removed from our systems, please contact us.`;
    const yourRightsHeaderPara3 = 'In certain circumstances, you have the following data protection rights:';

    const yourRights = [{
      name: 'The right to access, update or delete the information we have on you.',
      definition: `Whenever made possible, you can access, update or request deletion of your
      Personal Data directly within your account settings section. If you are unable to perform these actions yourself,
      please contact us to assist you.`,
    },
    {
      name: 'The right of rectification.',
      definition: 'You have the right to have your information rectified if that information is inaccurate or incomplete.',
    },
    {
      name: 'The right to object.',
      definition: 'You have the right to object to our processing of your Personal Data.',
    },
    {
      name: 'The right of restriction.',
      definition: 'You have the right to request that we restrict the processing of your personal information.',
    },
    {
      name: 'The right to data portability.',
      definition: `You have the right to be provided with a copy of the information we have on you in a structured,
      machine-readable and commonly used format.`,
    },
    {
      name: 'The right to withdraw consent.',
      definition: `You also have the right to withdraw your consent at any time
      where Ryu Games relied on your consent to process your personal information.`,
    }];

    const yourRightsHeaderPara4 = 'Please note that we may ask you to verify your identity before responding to such requests.';
    const yourRightsHeaderPara5 = `You have the right to complain to a Data Protection Authority about our
    collection and use of your Personal Data. For more information, please contact your local data protection authority
    in the European Economic Area (EEA).`;

    const serviceHeader = 'Service Providers';
    const servicePara1 = `We may employ third party companies and individuals to facilitate our Service ("Service Providers"),
    provide the Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.`;
    const servicePara2 = `These third parties have access to your Personal Data only to perform these tasks on our
    behalf and are obligated not to disclose or use it for any other purpose.`;

    const serviceSub1 = 'Analytics';
    const serviceSub1Para1 = 'We may use third-party Service Providers to monitor and analyse the use of our Service.';

    const googlePara = `Google Analytics is a web analytics service offered by Google that tracks and reports website traffic.
    Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services.
    Google may use the collected data to contextualise and personalise the ads of its own advertising network.
    For more information on the privacy practices of Google, please visit the Google Privacy Terms web page:
    https://policies.google.com/privacy?hl=en`;

    const linksHeader = 'Links to Other Sites';
    const linksPara1 = `Our Service may contain links to other sites that are not operated by us.
    If you click a third party link, you will be directed to that third party's site. We strongly
    advise you to review the Privacy Policy of every site you visit.`;
    const linksPara2 = `We have no control over and assume no responsibility for the content,
    privacy policies or practices of any third party sites or services.`;

    const childrensHeader = "Children's Privacy";
    const childrensPara1 = 'Our Service does not address anyone under the age of 18 ("Children").';
    const childrensPara2 = `We do not knowingly collect personally identifiable information from anyone
    under the age of 18. If you are a parent or guardian and you are aware that your Child has provided us with
    Personal Data, please contact us. If we become aware that we have collected Personal Data from children without
    verification of parental consent, we take steps to remove that information from our servers.`;

    const changesHeader = 'Changes to This Privacy Policy';
    const changesPara1 = `We may update our Privacy Policy from time to time.
    We will notify you of any changes by posting the new Privacy Policy on this page.`;
    const changesPara2 = `We will let you know via email and/or a prominent notice on our Service,
    prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.`;
    const changesPara3 = `You are advised to review this Privacy Policy periodically for any changes.
    Changes to this Privacy Policy are effective when they are posted on this page.`;

    const contactHeader = 'Contact Us';
    const contactPara = 'If you have any questions about this Privacy Policy, please contact us:';
    const contact = ['By email: team@ryu.games', 'By visiting this page on our website: http://www.ryu.games/datausage'];

    return (
      <div>
        <div className="container" style={{ paddingTop: '100px' }}>
          <div className="row">
            <div className="col col-xl-2 grey-col">
            </div>
            <Link to="/">
              <img alt="Ryu Games dark logo" src={dark} className="dark-logo-fixed"/>
            </Link>
            <div className="col col-md-10 offset-md-2 legal-container">
              <h1>{title}</h1>
              <h6>{effectiveDate}</h6>
              <hr />
              <p>{introContent}</p>

              <h2>{definitionHeader}</h2>
              <div className="indent">
              {defs.map((definition, i) => <div>
                  <b key={i}>{definition.word}</b>
                  <p>{definition.definition}</p>
                </div>)
              }
              </div>

              <h2>{infoHeader}</h2>
              <p>{infoIntro}</p>
              <b>{infoHeader1}</b><br /><br />
              <b><i>{infoSubHeader1}</i></b>
              <p>{infosubpara1}</p>
              {personaldata.map((p, i) => <ul>
                  <li key={i}>{personaldata[i]}</li>
                </ul>)
              }
              <p>{infosubpara2}</p>

              <b><i>{infoSubHeader2}</i></b>
              <p>{infosubpara3}</p>
              <p>{infosubpara4}</p>
              <p>{infosubpara5}</p>

              <b><i>{infoSubHeader3}</i></b>
              <p>{infosubpara6}</p>
              <p>{infosubpara7}</p>
              <p>{infosubpara8}</p>

              <b><i>{infoSubHeader4}</i></b>
              <p>{infosubpara9}</p>
              <p>{infosubpara10}</p>
              <p>{infosubpara11}</p>
              <p>{infosubpara12}</p>
              {cookies.map((cookieList, i) => <ul key={i}>
                  <li><b>{cookieList.type}</b> {cookieList.explaination}</li>
                </ul>)
              }
              <h2>{useHeader}</h2>
              <p>{usePara}</p>

              {uses.map((u, i) => <ul>
                  <li key={i}>{uses[i]}</li>
                </ul>)
              }
              <h2>{legalBasisHeader}</h2>
              <p>{legalBasisPara1}</p>
              <p>{legalBasisPara2}</p>
              {legalRequirements.map((r, i) => <ul>
                  <li key={i}>{legalRequirements[i]}</li>
                </ul>)
              }
              <h2>{retentionHeader}</h2>
              <p>{retentionPara1}</p>
              <p>{retentionPara2}</p>

              <h2>{transferHeader}</h2>
              <p>{transferPara1}</p>
              <p>{transferPara2}</p>
              <p>{transferPara3}</p>
              <p>{transferPara4}</p>

              <h2>{disclosureHeader}</h2>
              <b><i>{disclosureSub1}</i></b>
              <p>{DisclosureSub1Para1}</p>

              <p>{disclosureSub2}</p>
              <p>{DisclosureSub2Para1}</p>

              {legalbasis.map((basis, i) => <ul>
                  <li key={i}>{legalbasis[i]}</li>
                </ul>)
              }

              <h2>{securityHeader}</h2>
              <p>{securityPara}</p>

              <h2>{dntHeader}</h2>
              <p>{dntPara1}</p>
              <p>{dntPara2}</p>

              <h2>{yourRightsHeader}</h2>
              <p>{yourRightsHeaderPara1}</p>
              <p>{yourRightsHeaderPara2}</p>
              <p>{yourRightsHeaderPara3}</p>

              {yourRights.map((right, i) => <div className="indent">
                  <b key={i}>{right.name}</b>
                  <p>{right.definition}</p>
                </div>)
              }
              <p>{yourRightsHeaderPara4}</p>
              <p>{yourRightsHeaderPara5}</p>

              <h2>{serviceHeader}</h2>
              <p>{servicePara1}</p>
              <p>{servicePara2}</p>

              <b><i>{serviceSub1}</i></b>
              <p>{serviceSub1Para1}</p>

              <div className="indent">
                <b>Google Analytics</b>
                <p>{googlePara}</p>
              </div>

              <h2>{linksHeader}</h2>
              <p>{linksPara1}</p>
              <p>{linksPara2}</p>

              <h2>{childrensHeader}</h2>
              <p>{childrensPara1}</p>
              <p>{childrensPara2}</p>

              <h2>{changesHeader}</h2>
              <p>{changesPara1}</p>
              <p>{changesPara2}</p>
              <p>{changesPara3}</p>

              <h2>{contactHeader}</h2>
              <p>{contactPara}</p>
              {contact.map((c, i) => <ul>
                  <li key={i}>{contact[i]}</li>
                </ul>)
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { DataUsagePage as default };
