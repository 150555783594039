import React from 'react';
import {
  Link,
} from 'react-router-dom';

import logo from '../images/welcome/ryu_games_logo.png';

class LegalPage extends React.Component {
  render() {
    const title = 'Terms of Service';
    const effectiveDate = 'Effective date: June 9, 2021';

    const definitionHeader = '0.0 Definitions';

    const defs = [{
      word: '0.1 “Company”:',
      definition: 'Ryu Technologies, Inc.',
    },
    {
      word: '0.2 “User”:',
      definition: 'Any natural person who creates an “account”.',
    },
    {
      word: '0.3 “Developer”:',
      definition: "Any individual or company that enters an agreement with Company to use Company's software.",
    },
    {
      word: '0.4 “Pearls”:',
      definition: '“Company’s” valueless currency used for pearl tournaments” in games within “Company’s” catalog of games which have enabled tournaments.',
    },
    {
      word: '0.5 “Pearls” tournament”:',
      definition: 'A tournament in which multiple players enter. Each Pearls tournament will have an entry fee and prize that will be “paid” and “awarded” in “Pearls.”',
    },
    {
      word: '0.6 “Ryu Coin”:',
      definition: "A measure of a user’s balance for use in “real-money tournaments” in the “Ryu Ecosystem.” That measure can be viewed by users in any of Ryu Game's games. Ryu Coin is nontransferable.",
    },
    {
      word: '0.7 “Bonus”:',
      definition: '"Company” may choose to incentivize the purchase of “Ryu Coin” by offering a bonus of “Ryu Coin” upon purchase. For example, if a user makes a $5 purchase of Ryu Coin they may receive the 5 Ryu Coin they purchased, in addition to a 5 Ryu Coin bonus.',
    },
    {
      word: '0.8 “Real-money tournament”:',
      definition: 'A tournament in which multiple players enter. Each real-money tournament will have an entry fee and prize that will be “paid” and “awarded” in “Ryu Coin.” Further defined in 2.6.',
    },
    {
      word: '0.9 “Ryu Ecosystem”:',
      definition: "The “Ryu Ecosystem” refers to Ryu Game's consumer facing products, including but not limited to any game in “Company’s” catalog of games.",
    },
    {
      word: '0.10 “Account”:',
      definition: 'An account consists of the following: (a) A username (b) may have a corresponding email or phone number associated with it (c) an associated “Pearls” balance (d) an associated “Ryu Coin” balance.',
    },
    {
      word: '0.11 “Website”:',
      definition: 'Currently found at https://www.ryu.games',
    },
    {
      word: '0.12 “Objectionable Actions”',
      definition: 'Any user behavior taking place in the Ryu Ecosystem which Company has deemed to be unacceptable. Specific Objectionable Actions are outlined in Section 2.11.',
    }];

    const terms11 = [
      {
        title: '1.1.1 Real-money tournaments system:',
        term: 'Company may provide developer a software development kit (“SDK”) that will enable real-money tournaments in their game or games.',
      },
      {
        title: '1.1.2 Analytics Portal:',
        term: 'Company may provide developer a portal through which they can see analytics on their game’s users and tournament data including aggregate data from other developers.',
      },
      {
        title: '1.1.3 Promotion: ',
        term: 'Company reserves the right to promote any of developer’s game through social media, traditional media, or any other platform with the intent of increasing the number of users for developer.',
      }];

    const terms12 = [
      {
        title: '1.2.1 Obligation upon development in sandbox:',
        term: 'Developers must agree to the entirety of this terms of service including the software license found in 1.3.',
      },
      {
        title: '1.2.2 Requirements to launch production version:',
        term: "In order to launch a production version of a game that enables real-money tournaments, developer must have a production API key given by Company. To obtain that production API key, developer’s game must go through a quality assurance check with Company which may include but is not limited to an employee of Company testing a compiled version of developer’s game and asking questions to ensure that developer’s game is (a) a game of skill as defined by the relevant tests (b) works properly with understandable user experience and user interface. Company reserves the right to deny developer's request for production API key for any reason or to suspend it at any time. If a production API key is suspended, all of developer's other API keys may be suspended.",
      }];

    const terms14 = [
      {
        title: '1.3.1 Overview of End-User Agreement: ',
        term: 'This End-User License Agreement ("EULA") is a legal agreement between Developer and and Company. This EULA agreement governs your acquisition and use of any of Company’s SDK ("Software") directly from Company or indirectly through a Company authorized reseller or distributor (a "Reseller"). Please read this EULA agreement carefully before completing the installation process and using the software. It provides a license to use the Software and contains warranty information and liability disclaimers.',
      },
      {
        title: '1.3.2 Free trial or sandbox:',
        term: ' If you register for a free trial or sandbox version of the software, this EULA agreement will also govern that trial. By clicking "accept" or installing and/or using the Software, you are confirming your acceptance of the Software and agreeing to become bound by the terms of this EULA agreement.',
      },
      {
        title: '1.3.3 Acceptance of the EULA:',
        term: 'If you are entering into this EULA agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such entity and its affiliates to these terms and conditions. If you do not have such authority or if you do not agree with the terms and conditions of this EULA agreement, do not install or use the Software, and you must not accept this EULA agreement.',
      },
      {
        title: '',
        term: 'This EULA agreement shall apply only to the Software supplied by Company herewith regardless of whether other software is referred to or described herein. The terms also apply to any Company updates, supplements, Internet-based services, and support services for the Software, unless other terms accompany those items on delivery. If so, those terms apply. This EULA was created by EULA Template for Software.',
      },
      {
        title: '1.3.4 License Grant:',
        term: 'Company hereby grants you a personal, non-transferable licence to use the Software on your devices in accordance with the terms of this EULA agreement.',
      },
      {
        title: '',
        term: 'You are not permitted to:',
      }];

    const eula = [
      'Edit, alter, modify, adapt, translate or otherwise change the whole or any part of the Software nor permit the whole or any part of the Software to be combined with or become incorporated in any other software, nor decompile, disassemble or reverse engineer the Software or attempt to do any such things',
      'Reproduce, copy, distribute, resell or otherwise use the Software for any commercial purpose',
      'Allow any third party to use or view the Software on behalf of or for the benefit of any third party',
      'Use the Software in any way which breaches any applicable local, national or international law',
      'Use the Software for any purpose that Company considers is a breach of this EULA agreement',
    ];

    const terms142 = [
      {
        title: '1.3.4 Intellectual Property and Ownership Grant:',
        term: 'Company shall at all times retain ownership of the Software as originally downloaded by you and all subsequent downloads of the Software by you. The Software (and the copyright, and other intellectual property rights of whatever nature in the Software, including any modifications made thereto) are and shall remain the property of Company.',
      },
      {
        title: '',
        term: 'Company reserves the right to grant licences to use the Software to third parties.',
      },
      {
        title: '1.3.5 Termination: ',
        term: 'This EULA agreement is effective from the date you first use the Software and shall continue until terminated. You may terminate it at any time upon written notice to Company.',
      },
      {
        title: '',
        term: 'It will also terminate immediately if you fail to comply with any term of this EULA agreement. Upon such termination, the licenses granted by this EULA agreement will immediately terminate and you agree to stop all access and use of the Software. The provisions that by their nature continue and survive will survive any termination of this EULA agreement.',
      },
      {
        title: '1.3.6 Governing Law: ',
        term: 'This EULA agreement, and any dispute arising out of or in connection with this EULA agreement, shall be governed by and construed in accordance with the laws of California.',
      }];

    const devTitle = '1.0 Ryu Games Developer Terms of Service';
    const playerTitle = '2.0 Ryu Games User Terms of Service';

    const playerPara = 'Welcome and thank you for playing in the Ryu Ecosystem. We hope to make games more fun. If for any reason you are dissatisfied with anything, please contact our customer support at the bottom right of this screen. We work on an individual basis with every case.';

    const terms21 = [
      {
        title: '2.1.1 Account Creation and Pearls Eligibility: ',
        term: 'All users who can download a game in accordance to the age restriction (18+) are eligible to create an account and play with friends or offline in any app with friends and offline modes enabled in the Ryu Ecosystem. All users who are (18+) may play in Pearls Tournaments in any app in the Ryu Ecosystem.',
      },
      {
        title: '2.1.2 Real-money Tournament Eligibility: ',
        term: 'To be eligible to enter a real-money tournament or purchase Ryu Coin a user must (a) be a natural person 18 years or older (b) be physically present in a location that is explicitly listed as eligible in 2.1.4 (c) have agreed to the terms and conditions (d) has not been previously banned by Company for any reason unless explicitly allowed to recreate account.',
      },
      {
        title: '2.1.3 Removing Eligibility:',
        term: 'Company reserves the right to (a) verify the eligibility of any user at any time by asking for identification to verify age or physical location (b) remove eligibility of or temporarily ban any user at any time with or without notice for any reason including but not limited to Objectionable Actions outlined in Section 2.11.',
      },
      {
        title: '',
        term: 'If Company withdraws the eligibility of a user, that user may forfeit their Pearls balance. See “Refund Policy” for more information on suspended accounts.',
      },
      {
        title: '2.1.4 Eligible Locations: ',
        term: 'United States: Alabama, Alaska, California, Colorado, The District of Columbia, Florida, Georgia, Hawaii, Idaho, Indiana, Iowa, Illinois, Kansas, Kentucky Maine, Maryland, Massachusetts, Michigan, Minnesota, Mississippi, Missouri, Nebraska, Nevada, New Hampshire, New Jersey, New Mexico, New York, North Carolina, North Dakota, Ohio, Oklahoma, Oregon, Pennsylvania, Rhode Island, Texas, Utah, Vermont, Virginia, Washington, West Virginia, Wisconsin, Wyoming',
      },
      {
        title: '',
        term: 'Outside the United States: Not eligible',
      },
      {
        title: '2.1.5 Employee Eligibility: ',
        term: 'Employees of Company may play in real-money tournaments or Pearls tournaments for testing purposes to improve product but may never sell Ryu Coin earned from participating in real-money tournaments.',
      }];

    const terms22 = [
      {
        title: '2.2.1 Location Data: ',
        term: 'Users must share their location to play a real-money tournament in order to verify a user’s physical location to ensure eligibility.',
      },
      {
        title: '2.2.2 Other Account Information:',
        term: 'Company may store a user’s email, cell phone number or contact list when a user gives permissions and enters it into the app.',
      },
      {
        title: '',
        term: 'See “Privacy Policy” for more information on the collection and use of user data including GDPR compliance.',
      }];

    const terms25 = [
      {
        title: '2.5.1 Eligibility to buy Ryu Coin:',
        term: 'Users will be able to buy Ryu Coin if they are eligible for real-money tournaments. This means that a user will only be able to buy Ryu Coin in locations explicitly listed in 2.1.4',
      },
      {
        title: '2.5.2 Eligibility to cash out Ryu Coin:',
        term: 'Users will be able to cash out Ryu Coin if they are eligible for real-money tournaments, regardless of their current location. Meaning a player could buy Ryu Coin in a state that is explicitly listed in 2.1.4 and cash out in any other location.',
      },
      {
        title: '2.5.3 Buying Ryu Coin: ',
        term: 'To buy Ryu Coin, users will enter payment information. Users will only be allowed to buy Ryu Coin from Company. Attempting to buy an account with Ryu Coin loaded onto that account will be a breach of this terms of service.',
      },
      {
        title: '',
        term: 'Users will only be allowed to buy Ryu Coin from Company. This is technically enforced. Attempting to buy an account with Ryu Coin loaded onto that account will be a breach of this terms of service. ',
      },
      {
        title: '2.5.4 Cashing out Ryu Coin:',
        term: "To cash out Ryu Coin, users will find the cash out button in one of Ryu Games' games.",
      },
      {
        title: '',
        term: 'Users will only be allowed to cash out Ryu Coin from Company. Attempting to sell an account with Ryu Coin loaded onto that account will be a breach of this terms of service.',
      },
      {
        title: '',
        term: 'If a user breaches this terms of service, Company reserves the right to not allow the user to cash out Ryu Coin.',
      },
      {
        title: '',
        term: 'Purchased Ryu Coin and Ryu Coin received in a “Bonus” may not be cashed out. Only prizes paid out by tournaments may be cashed out.',
      }];

    const terms26 = [
      {
        title: '2.6.1 Signing into a game: ',
        term: 'To enter a tournament, a user will sign into any game in Company’s catalog through the game itself.',
      },
      {
        title: '2.6.2 Tournament selection: ',
        term: 'Once logged in, a user may select any of the Pealrs tournaments listed for that specific game. Every tournament will explicitly list: (a) the prize for that specific tournament (b) the entry fee (c) the number of players in a given tournament. If the number of players in a given tournament is a range, that range will be explicitly displayed.',
      },
      {
        title: '2.6.3 Joining a match:  ',
        term: 'Users pay an entry fee with Ryu Coin or Pearls when joining a match. No entry fee is paid if the user does not join the match.',
      },
      {
        title: '2.6.4 Winning:',
        term: ' A user wins a tournament if all of the following conditions are met: (a) the tournament has reached an end-state (b) the minimum number of players have played in the tournament (c) the user who wins has the highest score of all entrants or the user has tied the highest score of all entrants if not every entrant has that score. ',
      },
      {
        title: '',
        term: 'For real-money tournaments, in the event of a win, Company immediately pays out the user(s) who wins in Ryu Coin.',
      },
      {
        title: '',
        term: 'For Pearls tournaments, in the event of a win, Company immediately pays out the user(s) who wins in Pearls. ',
      },
      {
        title: '2.6.5 Losing: ',
        term: 'A user loses a tournament if all of the following conditions are met: (a) the tournament has reached an end-state (b) the minimum number of players have played in the tournament (c) another entrant has a higher score.',
      },
      {
        title: '',
        term: 'For real-money tournaments, in the event of a loss, Company immediately pays out the winner(s) in Ryu Coin meaning the user who loses will not receive the Ryu Coin that user paid as an entry fee.',
      },
      {
        title: '',
        term: 'For Pearls tournaments, in the event of a loss, Company immediately pays out the winner(s) in Pearls meaning the user who loses will not receive the Pearls that user paid as an entry fee.',
      },
      {
        title: '2.6.6 Tieing:',
        term: 'A user ties a tournament if all of the following conditions are met: (a) the tournament has reached an end-state (b) the minimum number of players have played in the tournament (c) all entrants have the same score or the prize divided by the number of players who have won is less than the entrance fee.',
      },
      {
        title: '',
        term: 'For real-money tournaments, in the event of a tie, Company immediately pays out the entrants their entry fee in Ryu Coin. Company does not receive Ryu Coin on ties.',
      },
      {
        title: '',
        term: 'For Pearls tournaments, in the event of a tie, Company immediately pays out the entrants their entry fee in Pearls. ',
      },
      {
        title: '2.6.7 Expired Tournaments:',
        term: 'A tournament expires if all of the following conditions are met: (a) the tournament has reached an end-state (b) the number of players who have submitted a score is less than the maximum allowed in the tournament.',
      },
      {
        title: '',
        term: 'In the event of an expired tournament, if the minimum number of players have not played, Company immediately pays out the entrants their entry fee. Company does not take a fee or cut on these types of expired tournaments. If the minimum number of players have played, Company pays out the entrants as defined in 2.6.4-2.6.6',
      },
      {
        title: '',
        term: 'For Pearls tournaments, in the event of an expired tournament, if the minimum number of players have not played, Company immediately pays out the entrants their entry fee in Pearls. If the minimum number of players have played, Company pays out the entrants as defined in 2.6.4-2.6.6',
      },
      {
        title: '2.6.8 Rounds:',
        term: 'Tournaments will consist of at least one round. If a tournament has more than one round, a specified number of players will advance each round. In the event there is a tie in a particular round resulting in more than the specified number of players advancing to a particular round, there will be a tie-breaker match.',
      },
      {
        title: '',
        term: 'For example, if a tournament is two rounds, with 5 total players and 2 players advancing to the second round, the two top scoring players in the first round will advance to the second round. Should there be a tie resulting in more than two players earning the lower of the top 2 scores, a tie-breaking match would be played and the winner would play the highest scoring player from the first round.',
      }];

    const terms27 = [
      {
        title: '2.7.1 - Hateful Conduct:',
        term: 'You may not promote violence against, threaten, or harass other people on the basis of race, ethnicity, national origin, caste, sexual orientation, gender, gender identity, religious affiliation, age, disability, or serious disease.',
      },
      {
        title: '2.7.2 - Violence:',
        term: 'You may not threaten violence against an individual or a group of people. We also prohibit the glorification of violence.',
      },
      {
        title: '2.7.3 - Terrorism/violent extremism:',
        term: 'You may not threaten or promote terrorism or violent extremism.',
      },
      {
        title: '2.7.4 - Child sexual exploitation:',
        term: 'We have zero tolerance for child sexual exploitation at Ryu Games.',
      },
      {
        title: '2.7.5 - Abuse Harassment:',
        term: 'You may not engage in the targeted harassment of someone, or incite other people to do so. This includes wishing or hoping that someone experiences physical harm.',
      },
      {
        title: '2.7.6 - Suicide or self-harm:',
        term: 'You may not promote or encourage suicide or self-harm.',
      },
      {
        title: '2.7.7 - Sensitive media, including graphic violence and adult content:',
        term: 'You may not post media that is gory or share violent or adult content. Media depicting sexual violence and/or assault is also not permitted.',
      },
      {
        title: '2.7.8 - Illegal or certain regulated goods or services:',
        term: 'You may not use our service for any unlawful purpose or in furtherance of illegal activities. This includes selling, buying, or facilitating transactions in illegal goods or services, as well as certain types of regulated goods or services.',
      },
      {
        title: '2.7.9 - Private Information:',
        term: 'You may not publish or post other people\'s private information (such as home phone number and address) without their express authorization and permission. We also prohibit threatening to expose private information or incentivizing others to do so.',
      },
      {
        title: '2.7.10 - Non-consensual nudity:',
        term: 'You may not post or share intimate photos or videos of someone that were produced or distributed without their consent.',
      },
      {
        title: '2.7.11 - Sexual Solicitation:',
        term: 'You may not encourage or coordinate sexual encounters or commercial sexual services between adults such as prostitution or escort services.',
      },
      {
        title: '2.7.12 - Platform manipulation and spam:',
        term: 'You may not use Company’s services in a manner intended to artificially amplify or suppress information or engage in behavior that manipulates or disrupts people’s experience in the Ryu Ecosystem.',
      },
      {
        title: '2.7.13 - Civic Integrity:',
        term: 'You may not use Company’s services for the purpose of manipulating or interfering in elections or other civic processes. This includes posting or sharing content that may suppress participation or mislead people about when, where, or how to participate in a civic process.',
      },
      {
        title: '2.7.14 - Impersonation:',
        term: 'You may not impersonate individuals, groups, or organizations in a manner that is intended to or does mislead, confuse, or deceive others.',
      },
      {
        title: '2.7.15 - Copyright and trademark:',
        term: 'You may not violate others’ intellectual property rights, including copyright and trademark.',
      },
      {
        title: '2.7.16 - Third-party advertising:',
        term: 'You may not submit, post, or display any content on or through our services that includes third-party advertising, including but not limited to sponsorship graphics, advertisements, promo codes, or QR codes.',
      },
    ];

    document.body.style = 'background: white;';

    return (
      <div>
        <div className="container" style={{ paddingTop: '100px' }}>
          <div className="row">
            <div className="col col-xl-2 grey-col">
            </div>
            <Link to="/">
              <img alt="Ryu Games dark logo" src={logo} className="dark-logo-fixed"/>
            </Link>
            <div className="col col-md-10 offset-md-2 legal-container">
              <h1>{title}</h1>
              <h6>{effectiveDate}</h6>
              <hr />
              <h2>{definitionHeader}</h2>
              <div className="indent">
                {defs.map((definition, i) => <div>
                    <b key={i}>{definition.word}</b>
                    <p>{definition.definition}</p>
                  </div>)
                }
              </div>
              <h2>{devTitle}</h2>
              <b>1.1 Ryu Coin’s Services to Developer</b>
              <div className="indent">
                {terms11.map((term, i) => <div>
                    <b key={i}>{term.title}</b>
                    <p>{term.term}</p>
                  </div>)
                }
              </div>
              <b>1.2 Developer Quality Assurance</b>
              <div className="indent">
                {terms12.map((term, i) => <div>
                    <b key={i}>{term.title}</b>
                    <p>{term.term}</p>
                  </div>)
                }
              </div>
              <b>1.3 End-User License Agreement</b>
              <div className="indent">
                {terms14.map((term, i) => <div>
                    <b key={i}>{term.title}</b>
                    <p>{term.term}</p>
                  </div>)
                }
                {eula.map((u, i) => <ul>
                    <li key={i}>{eula[i]}</li>
                  </ul>)
                }
              </div>
              <b>Intellectual Property and Ownership</b>
              <div className="indent">
                {terms142.map((term, i) => <div>
                    <b key={i}>{term.title}</b>
                    <p>{term.term}</p>
                  </div>)
                }
              </div>
              <h2>{playerTitle}</h2>
              <p>{playerPara}</p>
              <b>2.1 Eligibility</b>
              <div className="indent">
                {terms21.map((term, i) => <div>
                    <b key={i}>{term.title}</b>
                    <p>{term.term}</p>
                  </div>)
                }
              </div>
              <b>2.2 User Data</b>
              <div className="indent">
                {terms22.map((term, i) => <div>
                    <b key={i}>{term.title}</b>
                    <p>{term.term}</p>
                  </div>)
                }
              </div>
              <b>2.3 Gaming Law and Compliance</b>
              <p>{'Real-money tournaments will be available in the U.S. only in states where entry by participants of the game being played falls outside the state definition of gambling and is instead regulated as a contest of skill with an entrance fee for participants. Jurisdictions have different ways of measuring whether a game is one of skill or one of chance. When determining whether a game is a contest of skill for purposes of state law, Ryu Games will apply state-specific tests as appropriate.'}</p>
              <b>2.4 Cheating</b>
              <p>{'Cheating consists of but is not limited to the use of the following to gain an unfair advantage in any game in the Ryu Ecosystem: playing games with bots, playing games in simulators or with device farms, collusion with other players or collusion with bots, opening multiple accounts or any other action whether in-person or on a device that is intended to give any user an unfair advantage.'}</p>
              <p>{'In the event of a user cheating, Company reserves the right but is not limited to taking any of the following action: (a) suspension of the account (b) permanent ban of the user (c) forfeiture of Pearls balance.'}</p>
              <b>2.5 Buying and Cashing Out Ryu Coin</b>
              <div className="indent">
                {terms25.map((term, i) => <div>
                    <b key={i}>{term.title}</b>
                    <p>{term.term}</p>
                  </div>)
                }
              </div>
              <b>2.6 Entry Fees and Prizes</b>
              <div className="indent">
                {terms26.map((term, i) => <div>
                    <b key={i}>{term.title}</b>
                    <p>{term.term}</p>
                  </div>)
                }
              </div>
              <b>2.7 Objectionable Actions</b>
              <p>{'Company reserves the right to permanently or temporarily ban or make ineligible any User at any time for engaging in one or more Objectionable Actions. Company also reserves the right to change or restrict the ability for a User to change their username or profile picture if either constitutes an Objectionable Action. Objectionable Actions can manifest verbally, textually, visually, or otherwise. They include but are not limited to the behaviors listed below.'}</p>
              <div className="indent">
                {terms27.map((term, i) => <div>
                    <b key={i}>{term.title}</b>
                    <p>{term.term}</p>
                  </div>)
                }
              </div>
              <b>2.8 Refund Policy</b>
              <p>{'All sales are final and Company is not required to provide a refund for any reason unless applicable law requires otherwise. In the event a user under the age of 18 years old breaks our terms of service by depositing in our system, their account will be banned immediately once Company is aware that the breach occurred and no refund will be issued.'}</p>
              <p>{'If you believe your situation is an exception, to request a refund, please email'} <a href='mailto:team@ryu.games'>team@ryu.games</a>{' or call +1 (888) 945-8221.'}</p>

              <b>2.9 Anti Money Laundering</b>
              <p>{'It is a breach of this terms of service to intentionally lose a real-money tournament for any reason, including but not limited to an attempt to transfer money to another user.'}</p>

              <b>2.10 Arbitration</b>
              <p>{'The Parties agree that any dispute or controversy arising out of this Agreement shall be settled by Arbitration to be held in Cook county, state of Illinois, in accordance with the rules then in effect of the American Arbitration Association.  The arbitrator may grant injunctions or other relief in such dispute or controversy.  The decision of the arbitrator shall be final, conclusive and binding on the Parties.  Judgment may be entered on the arbitrator’s decision in any court having jurisdiction.  The Parties shall each pay one-half of the costs and expenses of such arbitration, and each of the parties shall separately pay counsel fees and expenses.'}</p>

              <b>2.11 Amendments</b>
              <p>{"Ryu Games may waive any of the terms or conditions of this Agreement, and this Agreement may be amended or modified, and posted to Ryu Games's “website.”"}</p>

              <b>2.12 Entire Agreement</b>
              <p>{'This Agreement constitutes the entire agreement with respect to the subject matter hereof, and supersedes all other prior agreements and understandings, both written and oral, among the parties hereto and their affiliates.'}</p>

              <p>For more information email <a href='mailto:team@ryu.games'>team@ryu.games</a></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { LegalPage as default };
