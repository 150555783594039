import React, { Component } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import ReactGA from 'react-ga';

import './css/style.css';
import './css/fbStyle.scss';
import './css/mailerlite.css';
import './css/homepage.css';

import withTracker from './withTracker';
import ScrollToTop from './ScrollToTop';

import HomePage from './homepage/index';
import HelloPage from './hello/hello';
import LegalPage from './legal/legal';
import DataUsagePage from './datausage/datausage';
import WebCheckout from './webcheckout/index';
import Support from './support/index';
import GenericNotFound from './notfound/index';
import VerificationSuccess from './verification/index';
import SubmitGamePage from './submitgame/index';
import FacebookPage from './facebook/index';
import ContactUs from './contactus/contactus';
import Docusign from './docusign/index';
import ChatTest from './chattest/index';
import Persona from './persona/index';

class App extends Component {
  render() {
    ReactGA.initialize('UA-131424937-1');
    return (
      <BrowserRouter>
        <ScrollToTop>
          <div className="App">
            <div className="App-intro">
              <Switch>
                <Route exact path="/" component={withTracker(HomePage)}/>
                <Route path="/hello" component={withTracker(HelloPage)}/>
                <Route path="/legal" component={withTracker(LegalPage)}/>
                <Route path="/datausage" component={withTracker(DataUsagePage)}/>
                <Route path="/checkout" component={withTracker(WebCheckout)}/>
                <Route path="/support" component={withTracker(Support)}/>
                <Route path="/verification-success" component={withTracker(VerificationSuccess)}/>
                <Route path="/submit" component={withTracker(SubmitGamePage)}/>
                <Route path="/facebook" component={withTracker(FacebookPage)}/>
                <Route path="/contactus" component={withTracker(ContactUs)}/>
                <Route path="/docusign" component={withTracker(Docusign)}/>
                <Route path="/chattest" component={withTracker(ChatTest)}/>
                <Route path="/persona" component={withTracker(Persona)}/>
                <Route component={GenericNotFound} />
              </Switch>
            </div>
          </div>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App;
