/* global paypal */

import React, { Component } from 'react';

class WebCheckout extends Component {
  constructor(props) {
    super(props);
    this.createButtons = this.createButtons.bind(this);

    this.createPayPalButton = this.createPayPalButton.bind(this);
    this.payment = this.payment.bind(this);
    this.onAuthorize = this.onAuthorize.bind(this);

    this.parseURL = this.parseURL.bind(this);
    this.invalidParams = this.invalidParams.bind(this);
    this.goToApp = this.goToApp.bind(this);
    this.goBackTapped = this.goBackTapped.bind(this);

    this.address = '';
    this.gameId = '';
    this.total = '';
    this.env = '';
    this.version = 1;
    this.sdkVersion = 1;
    this.uniqueId = '';
    this.depositOptionId = '';
    this.rsig = '';
    this.randomKey = '';

    this.state = {
      successfulPayment: true,
      total: '',
    };
  }

  goToApp(status) {
    const { goBackTapped } = this;
    if (status === 'success') {
      this.setState({
        successfulPayment: true,
      });
    } else {
      this.setState({
        successfulPayment: false,
      });
    }
    setTimeout(() => {
      document.getElementById('payment-title').style.display = 'none';
      document.getElementById('paypal-button').style.display = 'none';
      goBackTapped();
    }, 500);
  }

  goBackTapped() {
    const urlParams = new URLSearchParams(window.location.search);
    const scheme = urlParams.get('scheme');
    if (scheme === 'PCDemoScheme') {
      document.title = this.state.successfulPayment ? 'pc-paypal-success' : 'pc-paypal-fail';
    } else {
      window.location.href = `${scheme}://?result=${this.state.successfulPayment ? 'success' : 'fail'}`;
    }
  }

  createButtons() {
    console.log(`E: ${this.env}, A: ${this.address}, T: ${this.total}, V: ${this.version}, U: ${this.uniqueId}`);
    this.createPayPalButton();
  }

  onAuthorize(data, actions) {
    const isProd = this.env === 'prod' || this.env === 'sandbox';
    const NF = this.version > 2;
    let endpoint = `executePayment${NF ? 'NF' : ''}`;

    if (this.version === 4) {
      endpoint = 'charge';
    }

    const prefix = isProd ? '' : `${this.env}-`;
    const body = `https://${prefix}api.ryu.games/v2/payments/`;
    const url = `${body}${endpoint}/`;

    const { goToApp } = this;
    return actions.request.post(url, {
      paymentID: data.paymentID,
      payerID: data.payerID,
      address: this.address,
      uniqueId: this.uniqueId,
      total: this.total,
      gameId: this.gameId,
      pw: 'PAYPAL',
      version: this.sdkVersion,
      depositOptionId: this.depositOptionId,
      randomKey: this.randomKey,
      rsig: this.rsig,
    })
      .then((res) => {
        if (res.result) {
          goToApp('success');
        } else {
          goToApp('fail');
        }
      })
      .catch(() => {
        goToApp('fail');
      });
  }

  payment(data, actions) {
    return actions.payment.create({
      transactions: [{
        amount: {
          total: this.total,
          currency: 'USD',
        },
      }],
    });
  }

  createPayPalButton() {
    this.setState({
      total: this.total,
    });
    const isProd = this.env === 'prod' || this.env === 'sandbox';
    paypal.Button.render({
      env: isProd ? 'production' : 'sandbox',
      client: {
        sandbox: 'AXeXRYJxkaFNhfos99YS98GnFPQ4j6e_5vdZc1XVlLylF8qK41inz_xLGpGXiB29O_kKt2YKYACoo-bS',
        production: 'AaXwYf_0Sf4A_EkK2Mc-UpWBwPrIAFLL2ivPj9hxnOlj2VRIsknX_LCsL1-v5X7uA-Eb2LvI3FIlF20W',
      },
      style: {
        layout: 'vertical',
        size: 'responsive',
        shape: 'rect',
        color: 'gold',
      },
      funding: {
        allowed: [
          paypal.FUNDING.CARD,
          paypal.FUNDING.CREDIT,
        ],
        disallowed: [],
      },
      application_context: {
        shipping_preference: 'NO_SHIPPING',
      },
      commit: true,
      payment: this.payment,
      onAuthorize: this.onAuthorize,
    }, '#paypal-button');
  }

  parseURL() {
    const urlParams = new URLSearchParams(window.location.search);
    this.env = urlParams.get('env');
    this.address = urlParams.get('addr');
    this.gameId = urlParams.get('gameId');
    this.total = parseFloat(urlParams.get('amount'));
    this.uniqueId = urlParams.get('uniqueId');
    this.depositOptionId = urlParams.get('depositOptionId');
    this.randomKey = urlParams.get('randomKey');
    this.rsig = urlParams.get('rsig');

    if (this.uniqueId == null) {
      this.uniqueId = '';
    }

    const v = urlParams.get('version');
    if (v != null) {
      this.version = parseInt(v, 10);
    } else {
      this.version = 1;
    }

    const sv = urlParams.get('sdkVersion');
    if (sv != null) {
      this.sdkVersion = parseInt(sv, 10);
    } else {
      this.sdkVersion = 1;
    }
  }

  invalidParams() {
    console.log('ERROR - INVALID PARAMS');
    document.title = 'INVALID PARAMS';
  }

  componentDidMount() {
    this.parseURL();

    if (this.version === 4 && (this.depositOptionId == null || this.depositOptionId === '')) {
      this.invalidParams();
    } else if ((this.address === '' && this.uniqueId === '') || this.total === '' || (this.address == null && this.uniqueId == null) || this.total == null || this.env == null) {
      this.invalidParams();
    } else {
      this.createButtons();
    }
  }

  render() {
    return (
      <div>
        <div className='payment-title' id="payment-title">
          <h1 >{'The only way to start winning real-money tournaments in Ryu Games'}</h1>
        </div>
        <div id="paypal-button"></div>
      </div>
    );
  }
}

export { WebCheckout as default };
