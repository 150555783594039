import React from 'react';
import lottie from 'lottie-web';

import success from '../images/forms/success.json';
import err from '../images/forms/error.json';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.showSuccess = this.showSuccess.bind(this);
    this.showFailure = this.showFailure.bind(this);
  }

  showSuccess() {
    const svgContainer = document.getElementById('svgCheckContainer');
    lottie.loadAnimation({
      wrapper: svgContainer,
      animType: 'svg',
      loop: false,
      autoPlay: true,
      animationData: success,
    });
    document.getElementById('exampleModalLongTitle').innerHTML = "Your information was submitted. We'll be in touch.";
  }

  showFailure() {
    const svgContainer = document.getElementById('svgCheckContainer');

    lottie.loadAnimation({
      wrapper: svgContainer,
      animType: 'svg',
      loop: false,
      autoPlay: true,
      animationData: err,
    });
    document.getElementById('exampleModalLongTitle').innerHTML = 'There was an error submitting your form.';
  }

  render() {
    return (
      <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered" role="document" >
          <div className="modal-content" style={{
            border: 'none',
            boxShadow: '0px 0px 10px rgba(30,35,53,0.5)',
          }}>
            <div style={{ display: 'block', paddingBottom: '20px' }} id="svgCheckContainer" className="success-animation"></div>
            <div className="modal-header" style={{ border: 'none' }}>
              <h5 className="modal-title" id="exampleModalLongTitle">Please try again.</h5>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { Modal as default };
