import React from 'react';
import {
  Link,
} from 'react-router-dom';

import {
  Twitter,
  Facebook,
  Mail,
  Linkedin,
} from 'react-feather';

class WhiteFooter extends React.Component {
  render() {
    return (
      <div className="row white-footer-row justify-content-md-center">
        <div className="col-md white-footer-icon-container">
          <a target="_blank" rel="noopener noreferrer" href="http://twitter.com/_ryugames"><Twitter color="#0076FF" /></a>
          <a target="_blank" rel="noopener noreferrer" href="http://fb.com/officialryugames"><Facebook color="#0076FF" /></a>
          <a target="_blank" rel="noopener noreferrer" href="mailto:team@ryu.games"><Mail color="#0076FF" /></a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/ryu-games/"><Linkedin color="#0076FF" /></a>
        </div>
        <div className="col-md white-footer-legal-container">
          <Link className="white-footer-legal-text" to="/legal">Legal</Link>
          <Link className="white-footer-legal-text" to="/datausage">Data usage</Link>
          <span>©2020 Ryu Games</span>
        </div>
      </div>
    );
  }
}

export { WhiteFooter as default };
