import React from 'react';
import PropTypes from 'prop-types';

import {
  Link,
} from 'react-router-dom';

import logo from '../images/welcome/logo-dark-letters.png';

class Navbar extends React.Component {
  render() {
    let getInTouchButton = <div className="">
      <Link to="submit">
        <button className="btn nbtn" type="submit">Get in Touch</button>
      </Link>
    </div>;
    if (!this.props.getInTouch) {
      getInTouchButton = <div></div>;
    }

    return (
      <header>
        <nav className="navbar navbar-expand-md fixed-top justify-content-spacebetween">
          <div className="flex-grow-1">
            <Link to="/" >
              <img src={logo} className="" width="64" height="64" alt=""/>
            </Link>
          </div>
          {getInTouchButton}
        </nav>
      </header>
    );
  }
}

Navbar.propTypes = {
  getInTouch: PropTypes.bool,
};

export { Navbar as default };
