import React from 'react';

class HomePage extends React.Component {
  componentDidMount() {
    window.location.replace('http://flamestore.xyz');
  }

  render() {
    return (
      <div>
      <p
      style = {{
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        whiteSpace: 'pre',
        marginTop: '-10vh',
      }}
      >{'Redirecting to '}<a href='https://flamestore.xyz'>{'Flame 🔥'}</a></p>
      </div>
    );
  }
}

export { HomePage as default };
