import React from 'react';
import lottie from 'lottie-web';
import Navbar from '../homepage/navbar';
import Footer from '../homepage/footer';

import err from '../images/docusign/signed.json';

class Docusign extends React.Component {
  componentDidMount() {
    const svgContainer = document.getElementById('svgCheckContainer');
    lottie.loadAnimation({
      wrapper: svgContainer,
      animType: 'svg',
      loop: true,
      autoPlay: true,
      animationData: err,
    });
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="not-found-container">
          <div id="svgCheckContainer" className="error-animation"></div>
          <h1 className="notfound-title">Thanks for signing</h1>
          <h2 className="notfound-sub">- Ryu Games</h2>
        </div>
        <Footer />
      </div>
    );
  }
}

export { Docusign as default };
