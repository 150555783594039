import React from 'react';
import axios from 'axios';
import lottie from 'lottie-web';
import Navbar from '../homepage/navbar';
import spin from '../images/forms/spin.json';

function submitGame(gameInfo) {
  return new Promise((resolve, reject) => {
    const url = 'https://7td9wry6h3.execute-api.us-east-1.amazonaws.com/submitEmailForm'; // TODO

    axios.post(url, { gameInfo })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

class SubmitGamePage extends React.Component {
  constructor(props) {
    super(props);
    this.submitGameClicked = this.submitGameClicked.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.showSuccessModal = this.showSuccessModal.bind(this);

    this.child = React.createRef();
  }

  showSuccessModal() {
    document.getElementById('modal-trigger').click();

    this.child.current.showSuccess();

    document.getElementById('submit-game-button').innerHTML = 'Submitted';
    document.getElementById('submit-game-button').disabled = true;
  }

  showFailureModal() {
    document.getElementById('modal-trigger').click();

    this.child.current.showFailure();
    document.getElementById('submit-game-button').innerHTML = "LET'S DO THIS";
    document.getElementById('submit-game-button').disabled = false;
  }

  submitGameClicked(e) {
    const form = document.getElementById('game-form');
    form.reportValidity();
    if (form.checkValidity()) {
      this.submitForm(e);
    } else {
      console.log('Not Valid');
    }
  }

  submitForm(event) {
    event.preventDefault();

    console.log(document.getElementById('submit-email').value);

    const gameInfo = {
      companyName: document.getElementById('submit-company-name').value,
      email: document.getElementById('submit-email').value,
      fullName: document.getElementById('submit-full-name').value,
      country: document.getElementById('submit-country').value,
      submitMore: document.getElementById('submit-more').value,
    };

    document.getElementById('submit-game-button').disabled = true;

    const loaderContainer = document.getElementById('loaderContainer');
    lottie.loadAnimation({
      wrapper: loaderContainer,
      animType: 'svg',
      loop: true,
      autoPlay: true,
      animationData: spin,
    });
    const ldtt = document.getElementById('lets-do-this-text');

    if (ldtt) {
      ldtt.innerHTML = '';
    }

    submitGame(gameInfo)
      .then(() => {
        this.showSuccessModal();
      })
      .catch(() => {
        this.showFailureModal();
      });
  }

  render() {
    document.body.style = 'background: white;';
    const requiredStar = <span style={{ color: 'red' }}> *</span>;
    const optional = <span style={{ color: '#808080', fontWeight: '300' }}> (optional)</span>;
    return (
      <div>
        {/* Navbar */}
        <Navbar getInTouch={false}/>
        <button type="button" id='modal-trigger' data-toggle="modal" data-target="#exampleModalCenter" style={{ display: 'none' }}></button>
        <section className="jumbotron">
          <div className='container mt-5'>
            <h1 className="submit-game-heading centered">Request a Demo</h1>
            <br />
            <br />
            <form id="game-form">
              <div className="form-group submit-form-group">
                <label for="submit-company-name">Company Name{requiredStar}</label>
                <input type="text" className="form-control form-control-lg" id="submit-company-name" placeholder="Ryu Games" required/>
              </div>
              <div className="form-group submit-form-group">
                <label for="submit-email">Email{requiredStar}</label>
                <input type="email" className="form-control form-control-lg" id="submit-email" placeholder="ross@ryu.games" required/>
              </div>
              <div className="form-group submit-form-group">
                <label for="submit-full-name">Full Name{requiredStar}</label>
                <input type="text" className="form-control form-control-lg" id="submit-full-name" placeholder="Ross Krasner" required/>
              </div>
              <div className="form-group submit-form-group">
                <label for="submit-country">Country {optional}</label>
                <input type="text" className="form-control form-control-lg" id="submit-country" placeholder="U.S."/>
              </div>
              <div className="form-group submit-form-group">
                <label for="submit-more">Tell us more about your team and game(s){requiredStar}</label>
                <textarea className="form-control form-control-lg" id="submit-more" rows="3" required />
              </div>
            </form>
            <div className="centered col-md-8">

              <button className="btn nbtn" onClick={(e) => { this.submitGameClicked(e); } } id='submit-game-button'>
              <span id='loaderContainer' style={{ height: '10px', width: '10px' }}></span>
              <span id='lets-do-this-text' style={{ color: 'white' }}>LET'S DO THIS</span>
              </button>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export { SubmitGamePage as default };
