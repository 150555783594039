import React from 'react';
import lottie from 'lottie-web';
import {
  Link,
} from 'react-router-dom';

import Navbar from '../homepage/navbar';
import Footer from '../homepage/footer';

import err from '../images/notfound/error.json';

class WelcomePage extends React.Component {
  componentDidMount() {
    const svgContainer = document.getElementById('svgCheckContainer');
    lottie.loadAnimation({
      wrapper: svgContainer,
      animType: 'svg',
      loop: true,
      autoPlay: true,
      animationData: err,
    });
  }

  render() {
    const title = 'Oops!';
    const sub = "We couldn't find the page...";
    return (
      <div>
        <Navbar />
        <div className="not-found-container">
          <div id="svgCheckContainer" className="error-animation"></div>
          <h1 className="notfound-title">{title}</h1>
          <h2 className="notfound-sub">{sub}</h2>
          <Link className="btn nbtn notfound-btn" to="/">
            GO BACK HOME
          </Link>
        </div>
        <Footer />
      </div>
    );
  }
}

export { WelcomePage as default };
