/* global Persona */

import React, { Component } from 'react';

class PersonaVerification extends Component {
  constructor(props) {
    super(props);
    this.address = '';
    this.env = '';
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    this.env = urlParams.get('env');
    this.address = urlParams.get('addr');
    if (this.env == null || this.address == null) {
      document.title = 'INVALID PARAMS';
      return;
    }

    this.client = new Persona.Client({
      templateId: 'itmpl_2zTkUawiVWUnSSQE6LLzzZ8E',
      environment: this.env === 'prod' ? 'production' : 'sandbox',
      referenceId: this.address,
      onReady: () => this.client.open(),
      onComplete: () => {
        document.title = 'SUCCESS';
      },
      onCancel: () => {
        document.title = 'ERROR';
      },
      onError: () => {
        document.title = 'ERROR';
      },
    });
  }

  render() {
    return (<div></div>);
  }
}

export { PersonaVerification as default };
