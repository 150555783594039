import React from 'react';
import {
  Link,
} from 'react-router-dom';

import logo from '../images/welcome/ryu_games_logo.png';

class ContactUs extends React.Component {
  render() {
    document.body.style = 'background: white;';
    return (
      <div>
        <div className="container" style={{ paddingTop: '100px' }}>
          <div className="row">
            <div className="col col-xl-2 grey-col">
            </div>
            <Link to="/">
              <img alt="Ryu Games dark logo" src={logo} className="dark-logo-fixed"/>
            </Link>
            <div className="col col-md-10 offset-md-2 legal-container">
              <h1>{'Contact Us'}</h1>
              <hr />
              <h2>{'Recommended'}</h2>
              <p><b>Use the support icon at the bottom right of the screen.</b></p>
              <h2>{'Other'}</h2>
              <p><b>Phone:</b></p>
              <p>+1 (888) 945-8221</p>
              <p><b>Email:</b></p>
              <p>team@ryu.games</p>
              <p><b>Letter:</b></p>
              <p>Ryu Technologies, Inc</p>
              <p>2261 Market Street #4001</p>
              <p>San Francisco CA, 94114</p>
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { ContactUs as default };
