import React from 'react';
import FacebookLogin from 'react-facebook-login';

let loadedData = {};

class FacebookPage extends React.Component {
  componentDidMount() {
    window.app = this;
  }

  call = () => loadedData;

  render() {
    document.body.style = 'background: white;';
    document.title = 'Loading';

    const responseFacebook = (response) => {
      window.app = this;
      console.log('Loaded');

      const {
        name,
        id,
        friends,
      } = response;

      if (name != null && id != null) {
        const { data } = friends;
        const facebookFriends = [];
        data.forEach((friend) => {
          const obj = {
            name: friend.name,
            facebookId: friend.id,
          };
          facebookFriends.push(obj);
        });

        const facebookId = id;

        const args = {
          name,
          facebookId,
          facebookFriends,
        };

        loadedData = args;
        document.title = 'Loaded';
      } else {
        document.title = 'Error';
      }
    };

    return (
      <div style={{
        width: '100%',
        backgroundColor: 'white',
      }}>
        <FacebookLogin
          appId="3218584671544486"
          autoLoad={true}
          fields="name,email,friends"
          scope="user_friends"
          callback={responseFacebook}
          cssClass='facebook facebook-ryu'
          textButton="Connect to Facebook"
          disableMobileRedirect={true}
          isMobile={true}
        />
      </div>
    );
  }
}

export { FacebookPage as default };
