import React from 'react';
import {
  Link,
} from 'react-router-dom';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import axios from 'axios';

import {
  Zap,
  CreditCard,
  DollarSign,
  TrendingUp,
} from 'react-feather';

import WhiteFooter from '../common/whitefooter';
import Modal from '../common/modal';

import cropped from '../images/welcome/ryu_games_logo.png';

class HelloPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      gameData: {},
      photoIndex: 0,
      isOpen: false,
      isSocial: false,
      isX: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.loadGameData = this.loadGameData.bind(this);
  }

  loadGameData() {
    let uniqueId = '';
    const arr = window.location.pathname.split('/');
    if (arr.length === 3) {
      [, , uniqueId] = arr;
      if (uniqueId.startsWith('s-')) {
        this.setState({ isSocial: true });
      } else if (uniqueId.startsWith('x-')) {
        this.setState({ isX: true });
      }
    } else {
      this.props.history.push('/');
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const isDev = urlParams.get('dev');
    let prefix = '';
    if (isDev != null) {
      prefix = 'dev-';
    }

    axios.get(`https://${prefix}api.ryu.games/v2/dashboard/getGameDataForHelloPage?uniqueId=${uniqueId}`)
      .then((res) => {
        const { result } = res.data;
        console.log(result);
        this.setState({
          gameData: result,
        }, () => {
          this.updateWindowDimensions();
        });
      })
      .catch((err) => {
        this.props.history.push('/');
      });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    if (Object.keys(this.state.gameData).length === 0) { return; }

    this.setState({ width: window.innerWidth, height: window.innerHeight }, () => {
      const element1 = document.getElementById('sketch-col');
      const element = document.getElementById('title-col');
      if (window.innerWidth < 1350) {
        element.classList.remove('offset-lg-1');
        element.classList.remove('col-md-5');
        element.classList.add('col-12');
      } else {
        element.classList.add('offset-lg-1');
        element.classList.remove('col-12');
        element.classList.add('col-md-5');
      }

      if (window.innerWidth < 961) {
        element1.classList.remove('col-md-7');
        element1.classList.add('col-12');
      } else {
        element1.classList.remove('col-12');
        element1.classList.add('col-md-7');
      }

      if (window.innerWidth < 640) {
        const boostVidCol = document.getElementById('boost-vid-col');
        boostVidCol.classList.remove('order-1');
        boostVidCol.classList.add('order-3');
      } else {
        const boostVidCol = document.getElementById('boost-vid-col');
        boostVidCol.classList.remove('order-3');
        boostVidCol.classList.add('order-1');
      }
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.loadGameData();
  }

  render() {
    const { photoIndex, isOpen } = this.state;

    let urls = this.state.gameData.sketchURLs;
    let allFiveImage = '';
    if (urls == null) {
      urls = [];
    } else if (urls.length > 0) {
      [allFiveImage] = urls;
    }

    const images = [
      allFiveImage,
    ];

    const hasIndividualPhoneSketches = urls.length > 1;

    const smallPhoneImages = urls.slice(1);

    const { gameName } = this.state.gameData;
    const title = `Monetize ${gameName} with cash tournaments.`;
    const socialTitle = `${gameName} with Friends.`;
    const subTitle = "Watch your KPIs soar after one afternoon. We'll even integrate it for you.";
    const socialSubTitle = 'Grow by connecting your players to their friends. Get early access.';
    const gameSketch = <img alt='Ryu Games Sketch' src={allFiveImage} className='hello-game-sketch'></img>;
    const gameLogo = <img alt='Game Icon' src={this.state.gameData.publisherLogoURL}></img>;
    const gameWebsite = this.state.gameData.publisherURL;

    const feature1title = 'Fast and easy';
    const feature1subtitle = 'A breeze to integrate.';
    const feature2title = 'No up front or recurring cost';
    const feature2socialTitle = 'No upfront cost';
    const feature2subtitle = "We split the revenue. That's it.";
    const feature2socialSubtitle = "We're investing in you.";
    const feature3title = 'We take care of payments';
    const feature3socialTitle = 'Increase retention';
    const feature3subtitle = 'You have better things to do.';
    const feature3socialSubtitle = 'Social users stick around.';
    document.body.style = 'background: white;';

    const isOnSmallPhone = this.state.width < 776;
    const imageArray = (isOnSmallPhone && hasIndividualPhoneSketches) ? smallPhoneImages : images;

    const launchText = <span>
    <i>Everyone starts somewhere.</i>
    <br />
    <br />
    Why monetize if you don’t have users? The most profitable games have DAUs who keep coming back from more. Don’t scare them off with excessive ads and IAPs.
    <br />
    <br />
    Launch is built to organically grow a game’s DAUs until it’s worth monetizing. By making any game “X with Friends.” Launch takes your game to the next level.
    </span>;

    const boostText = <span>
    <i>The next level.</i>
    <br />
    <br />
    Once your game lifts off -- it’s time to monetize. Without changing your code, the Launch SDK can toggle cash tournaments, where users pay an entry fee and compete for a cash prize.
    <br />
    <br />
    It’s as easy as flipping a switch. We handle everything for you, including payment processing, account creation, geofencing, and more.
    </span>;

    const boostForTournamentsText = <span>
    <i>Ads kill games.</i>
    <br />
    <br />
    Your game is profitable, but if users are complaining about ads, they won’t stick around for long. It’s time to rethink your monetization model.
    <br />
    <br />
    The Boost SDK replaces obtrusive ads with cash tournaments where users pay an entry fee and compete for a cash prize. We handle everything for you, including payment processing, account creation, geofencing, and more.
    </span>;

    const socialVideo = <video key='2' className='animated-gif-hello' autoPlay loop muted playsInline poster='../images/welcome/poster-2.png'>
      <source src='https://home-feed-photos.s3.amazonaws.com/websitecontent/social_energy.mp4'></source>
    </video>;

    const tournamentVideo = <video key='1' className='animated-gif-hello' autoPlay loop muted playsInline poster='../images/welcome/poster.png'>
      <source src='https://home-feed-photos.s3.amazonaws.com/websitecontent/infinity_energy_VS_3.mp4'></source>
    </video>;

    if (Object.keys(this.state.gameData).length === 0) {
      return (<span></span>);
    }

    return (
      <div>
      <Modal />
        <div className='row handshake-row' style={{ height: '100px' }}>
          <div id='handshake-col' className='handshake-items-col col-md-4'>
              <div className='handshake-items-container' style={{ height: '100px' }}>
                <Link to='/' rel='noopener noreferrer' target='_blank'>
                  <img alt='Ryu Games Logo' src={cropped} className='ryu-logo-hello'></img>
                </Link>
                <span className='emoji-handshake' role='img' aria-label='handshake emoji'>🤝</span>
                <a href={gameWebsite} rel='noopener noreferrer' target='_blank'>
                  {gameLogo}
                </a>
              </div>
          </div>
        </div>
        <div className='hello-container row'>
        <div className='hello-svg-back'>
        </div>
          <div id='title-col' className='col col-md-5 offset-lg-1 hello-title-col'>
              <h1 className='hello-title'>{this.state.isSocial ? socialTitle : title}</h1>
              <h2 className='hello-sub-title'>{this.state.isSocial ? socialSubTitle : subTitle}</h2>
          </div>
          <div style={{ height: '100%' }} id='sketch-col' className='col col-md-7 hello-sketch-col' onClick={() => this.setState({ isOpen: true })}>
              {gameSketch}
              {isOpen && (
                <Lightbox
                  mainSrc={imageArray[photoIndex]}
                  nextSrc={isOnSmallPhone && hasIndividualPhoneSketches ? imageArray[(photoIndex + 1) % imageArray.length] : null}
                  prevSrc={isOnSmallPhone && hasIndividualPhoneSketches ? imageArray[(photoIndex + imageArray.length - 1) % imageArray.length] : null}
                  onCloseRequest={() => this.setState({ isOpen: false })}
                  onMovePrevRequest={() => this.setState({
                    photoIndex: (photoIndex + imageArray.length - 1) % imageArray.length,
                  })
                  }

                  onMoveNextRequest={() => this.setState({
                    photoIndex: (photoIndex + 1) % imageArray.length,
                  })
                  }
                />
              )}
          </div>
        </div>
        <div className='row feature-list-row'>
          <ul className='feature-list offset-md-1 col-md-10'>
            <li className='first-feature-list-li'>
              <div>
              <div className='feature-img'>
                <Zap style={{ margin: 'auto' }} color='#0197FB'></Zap>
              </div>
                <h3>{feature1title}</h3>
                <h4>{feature1subtitle}</h4>
              </div>
            </li>
            <li className='center-feature-list-li'>
              <div className='center-feature-list'>
              <div className='feature-img'>
                <DollarSign style={{ margin: 'auto' }} color='#0197FB'></DollarSign>
              </div>
                <h3>{this.state.isSocial ? feature2socialTitle : feature2title}</h3>
                <h4>{this.state.isSocial ? feature2socialSubtitle : feature2subtitle}</h4>
              </div>
            </li>
            <li className='third-feature-list-li'>
              <div >
                <div className='feature-img'>
                  {
                    this.state.isSocial
                      ? <TrendingUp style={{ margin: 'auto' }} color='#0197FB'></TrendingUp>
                      : <CreditCard style={{ margin: 'auto' }} color='#0197FB'></CreditCard>
                  }
                </div>
                <h3>{this.state.isSocial ? feature3socialTitle : feature3title}</h3>
                <h4>{this.state.isSocial ? feature3socialSubtitle : feature3subtitle}</h4>
              </div>
            </li>
          </ul>
        </div>
        <div className='row launch-boost-row' style={{ backgroundColor: 'white' }}>
          <div className='col col-lg-7' style={{ display: this.state.isSocial ? 'block' : 'none' }}>
            <div className='launch-boost-text-container launch-text-ctn'>
              <h1><span role='img' aria-label='emoji'>🌱</span> Launch</h1>
              <p>{launchText}</p>
            </div>
          </div>
          <div className='col col-lg-5 hello-video-col' style={{ display: this.state.isSocial ? 'block' : 'none' }}>
            {socialVideo}
          </div>
          <div className='row boost-row'>
            <div className='col col-lg-5 hello-video-col order-1' id='boost-vid-col'>
              {tournamentVideo}
            </div>
            <div className='col col-lg-7 order-2'>
              <div className='launch-boost-text-container boost-text-ctn'>
                <h1><span role='img' aria-label='emoji'>🚀</span> Boost</h1>
                <p>{this.state.isSocial ? boostText : boostForTournamentsText}</p>
              </div>
            </div>
          </div>
        </div>
        <WhiteFooter />
      </div>
    );
  }
}

export { HelloPage as default };
