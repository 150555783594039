import React, { Component } from 'react';
import lottie from 'lottie-web';

import Navbar from '../homepage/navbar';
import Footer from '../homepage/footer';

import arrow from '../images/support/loader.json';

class Support extends Component {
  constructor(props) {
    super(props);
    this.showZenDesk = this.showZenDesk.bind(this);
  }

  componentDidMount() {
    const svgContainer = document.getElementById('svgCheckContainer');
    lottie.loadAnimation({
      wrapper: svgContainer,
      animType: 'svg',
      loop: true,
      autoPlay: true,
      animationData: arrow,
    });

    this.showZenDesk();
  }

  showZenDesk() {
    if (window.zE) {
      window.zE('webWidget', 'show');
      window.zE('webWidget', 'open');
    } else {
      setTimeout(() => {
        this.showZenDesk();
      }, 50);
    }
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className='not-found-container'>
          <h1 style={{ textAlign: 'center', paddingTop: '10vh' }}>Loading support</h1>
          <div id="svgCheckContainer" style={{ height: '40vh' }} className="error-animation"></div>
        </div>
        <Footer />
      </div>
    );
  }
}

export { Support as default };
