import React from 'react';
import Navbar from '../homepage/navbar';
import Footer from '../homepage/footer';

class ChatTest extends React.Component {
  componentDidMount() {
    window.zESettings = {
      webWidget: {
        contactForm: {
          suppress: true,
        },
        helpCenter: {
          suppress: true,
        },
        chat: {
          suppress: false,
        },
      },
    };
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="not-found-container">
          <h1 className="notfound-title">Chat test</h1>
        </div>
        <Footer />
      </div>
    );
  }
}

export { ChatTest as default };
