import React from 'react';

class VerificationSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      callBack: '',
    };
  }

  componentDidMount() {
    this.parseURL();
  }

  changeTitle() {
    document.title = 'READY';
  }

  parseURL() {
    const urlParams = new URLSearchParams(window.location.search);
    this.setState({
      callBack: urlParams.get('JSONcallBackStatus'),
    }, this.changeTitle);
  }

  render() {
    return (
      <div>
        <p id="call-back-data">{this.state.callBack}</p>
      </div>
    );
  }
}

export { VerificationSuccess as default };
