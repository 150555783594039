import React from 'react';
import {
  Link,
} from 'react-router-dom';

class Footer extends React.Component {
  render() {
    return (
      <footer className="container footer">
          <div className="float-right">
            <p className="my-0"><a href="mailto:team@ryu.games" >team@ryu.games</a></p>
            <p className="my-0"><Link to="datausage">Privacy Policy</Link></p>
            <p className="my-0"><Link to="legal">Terms and Conditions</Link></p>
            <p className="my-0"><Link to="contactus">Contact Us</Link></p>
          </div>
          <p className="my-0">&copy; 2021 Ryu Technologies, Inc.</p>
          <p className="my-0">1600 E 3rd Ave, San Mateo, CA 94401</p>
          <p className="my-0">+1 (888) 945-8221</p>
      </footer>
    );
  }
}

export { Footer as default };
